export interface PathwayConfigurationParameter {
  data_type: ConfigurationsParameterType,
  default_value: any,
  description: string,
  key: string
}

export enum ConfigurationsParameterType {
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  INTEGER = "INTEGER",
  DOUBLE = "DOUBLE"
}
