import { Component, Input, OnInit, Output, EventEmitter, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select',
  templateUrl: './app-select.component.html',
  styleUrls: ['./app-select.component.scss'],

  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => AppSelectComponent),
       multi: true
    }
 ]
})
export class AppSelectComponent implements OnInit, ControlValueAccessor {
  @HostBinding('class.d-inline-block') classInlineBlock: boolean = false;

  @Input() asFilter: boolean;
  @Input() showCheckboxes: boolean;
  
  @Input() selectedItemsOnTop: boolean = true;
  @Input() autoSortOnLabel: boolean;
  
  @Input() hideSelected: boolean; //placeholder, functionality not implemented yet
  
  @Input() selectClass: string;

  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() change: EventEmitter<string> = new EventEmitter();
  @Output() close: EventEmitter<string> = new EventEmitter();
  
  // pass through ng-select
  @Input() clearable: boolean;
  @Input() searchable: boolean;
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() bindValue: any;
  @Input() searchFn: any;

  @Input('items')
  public set items(items: any) {
    if(this.orgItems !== items) {
      this.orgItems = items;
      this.rebuildItems();
    }
  }

  @Input('bindLabel')
  public set bindLabel(bindLabel: string) {
    if(this._bindLabel !== bindLabel) {
      this._bindLabel = bindLabel;
      this.bindCustomLabels();
    }
  }
  public get bindLabel() {
    return this._bindLabel;
  }
  
  public orgItems: any[];
  public usedItems: any[];
  public disabled: boolean;

  public value: any;
  public onChange: any = () => { };
  public onTouched: any = () => { };

  private _bindLabel: string;

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if(this.asFilter) {
      this.classInlineBlock = true;
    }

    this.translateService.onLangChange.subscribe(() => {
      this.fillTranslatedLabel();
    });
  }

  writeValue(value: any): void {
    this.value = value;
    this.rebuildItems();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onNgModelChange(e: any) {
    this.rebuildItems();
    this.onChange(e);
  }

  searchHandler(event?: any) {
    this.search.emit(event);
  }

  changeHandler(event?: any) {
    this.change.emit(event);
  }
  
  closeHandler(event?: any) {
    this.close.emit(event);
  }

  rebuildItems() {
    this.usedItems = Object.assign([], this.orgItems);

    this.putSelectedItemsOnTop();

    this.bindCustomLabels();
  }

  putSelectedItemsOnTop() {
    if(this.selectedItemsOnTop) {
      if(Array.isArray(this.value)) {
        this.value.forEach(selectedItem => {
          if(this.isItemIncluded(selectedItem)) {
            let removed: any[] = this.usedItems.splice(this.getItemIndex(selectedItem), 1);
            this.usedItems = removed.concat(this.usedItems);
          }
        });
      } else {
        if(this.isItemIncluded(this.value)) {
          let removed: any[] = this.usedItems.splice(this.getItemIndex(this.value), 1);
          this.usedItems = removed.concat(this.usedItems);
        }
      }
    }
  }

  getItemIndex(singleSelectedItem: any):number {
    if(this.bindValue) {
      let found = this.usedItems.filter(_item => {
        return _item[this.bindValue] === singleSelectedItem
      });

      if(found && found.length) {
        return this.usedItems.indexOf(found[0]);
      } else {
        return -1;
      }
      
    } else {
      return this.usedItems.indexOf(singleSelectedItem);
    }
  }

  isItemIncluded(singleSelectedItem: any):boolean {
    return (this.getItemIndex(singleSelectedItem) > -1);
  }

  bindCustomLabels() {
    if(!this.usedItems) {
      return;
    }

    if(!this.bindLabel) {
      this.fillTranslatedLabel();
      return;
    }

    let bindLabelParts: string[] = this.bindLabel.split('.');

    this.usedItems.forEach(_item => {
      let context:any = _item;

      if(context) {
        bindLabelParts.forEach(part => {
          if(context[part]) {
            context = context[part];
          }
        });
      }

      if(context) {
        _item.label = String(context);
      }
    });

    this.sortOnLabel();
  }

  fillTranslatedLabel() {
    let translateJobs: Array<any> = new Array();

    this.usedItems.forEach(_item => {
      // if(!_item.label && _item?.translationKey) {
      if(_item?.translationKey) {

        translateJobs.push({
          key: _item.translationKey,
          item: _item
        });
      }
    });

    if(translateJobs?.length) {
      this.translateService.get(translateJobs.map(i => i.key)).subscribe(_translations => {
        translateJobs.forEach((translateJob) => {
          translateJob.item.label = _translations[translateJob.key];
        });

        this.sortOnLabel();
      });
    } else {
      this.sortOnLabel();
    }
  }

  sortOnLabel() {
    if(this.autoSortOnLabel) {
      this.usedItems = this.usedItems.sort((a, b) => {
        if(a.hasOwnProperty('label')) {
          if(a?.label < b?.label) { return -1; }
          if(a?.label > b?.label) { return 1; }
        }
        return 0;
      });

      this.usedItems = Object.assign([], this.usedItems);

      this.putSelectedItemsOnTop();
    }
  }
}
