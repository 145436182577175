import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigurationsParameterType, PathwayConfigurationParameter } from '../../interfaces/pathway-configuration-parameter.interface';
import { DateValidator } from '../../validators/date-validator';

@Component({
  selector: 'app-configuration-parameter',
  templateUrl: './configuration-parameter.component.html'
})
export class ConfigurationParameterComponent implements OnInit {
  @Output() onParameterCreated = new EventEmitter<{ parameter: PathwayConfigurationParameter, deleteKey: string }>();

  public parameter: PathwayConfigurationParameter;

  public isSaving: boolean = false;
  public validationVisible: boolean = false;

  public form: FormGroup;
  public dataTypes = Object.values(ConfigurationsParameterType).map((value) => ({ value: value }));
  public configurationsParameterType = ConfigurationsParameterType;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    const data_type = this.parameter?.data_type ? { value: this.parameter?.data_type, label: this.parameter?.data_type } : null;

    this.form = new FormGroup({
      key: new FormControl(this.parameter?.key, [Validators.required, Validators.pattern(/^[A-Z_]{1,}$/)]),
      description: new FormControl(this.parameter?.description, Validators.required),
      dataType: new FormControl(data_type, Validators.required),
      defaultValue: new FormControl(this.parameter?.default_value, Validators.required)
    });

    this.manageDefaultValueValidator(this.parameter?.data_type);

    this.form.get('dataType')?.valueChanges.subscribe((type: { value: ConfigurationsParameterType, label: ConfigurationsParameterType }) => {
      this.form.get('defaultValue')?.reset();
      this.form.get('defaultValue')?.markAsPristine();
      this.manageDefaultValueValidator(type.value);

      this.validationVisible = false;
    });
  }

  onHandleSubmit() {
    if (this.form.valid) {
      this.validationVisible = false;
      const parameter: PathwayConfigurationParameter = {
        key: this.form.get('key')?.value,
        description: this.form.get('description')?.value,
        data_type: (this.form.get('dataType')?.value?.value) ?
          this.form.get('dataType')?.value?.value :
          this.form.get('dataType')?.value,
        default_value: this.form.get('defaultValue')?.value
      }

      this.onParameterCreated.emit({ parameter, deleteKey: this.parameter?.key });
      this.bsModalRef.hide();
    } else {
      this.validationVisible = true;
    }
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  manageDefaultValueValidator(type?: ConfigurationsParameterType) {
    let validators: ValidatorFn[] = [Validators.required];

    switch (type) {
      case ConfigurationsParameterType.BOOLEAN:
        validators.push(Validators.pattern(/^(true|false)$/));
        break;
      case ConfigurationsParameterType.DATE:
        validators.push(DateValidator.validate());
        break;
      case ConfigurationsParameterType.DATE_TIME:
        validators.push(DateValidator.validate());
        break;
      case ConfigurationsParameterType.INTEGER:
        validators.push(Validators.pattern(/^\d+$/));
        break;
      case ConfigurationsParameterType.DOUBLE:
        validators.push(Validators.pattern(/^[0-9]+.[0-9]+$/));
        break;
      default:
        break;
    }

    this.form.get('defaultValue')?.setValidators(validators);
    this.form.get('defaultValue')?.updateValueAndValidity();
  }
}
